:root {
    --lightBG: #eaeff3;
    --lightGrey: #b3bdd9;
    --purple: #8a288f;
    --blue: #0092d8;
    --lightGreenBG: #d6e6c4;
    --danger: #e54e4e;
    --matchAddDayBG: #b3bdd9;
}
.pac-container {
    background-color: white;
    margin-top: 2px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pac-item,
.pac-item-query {
    color: black;
    margin: 5px;
    padding: 5px;
}
.pac-item:hover {
    background: lightgrey;
}
.mb0 {
    margin-bottom: 0;
}
.msAuto {
    margin-left: auto;
}
.daysSelection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;
    width: 100%;
}
.daysSelection > div {
    position: relative;
    z-index: 5;
    /* text-align: center; */
    width: 100%;
}
.daysSelection .progressBar {
    position: absolute;
    left: 0;
    bottom: 12px;
    z-index: 20;
    height: 4px;
    background-color: var(--purple);
}
/* .daysSelection .currentDay::before,.daysSelection .currentDay::after{ */
.daysSelection h6 {
    font-size: 12px;
}
.daysSelection::before {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 20px;
    width: calc(100% - 40px);
    height: 4px;
    z-index: -1;
    background-color: var(--lightGrey);
}
.daysSelection > div:last-child::after {
    content: "";
    position: absolute;
    width: calc(100% - 20px);
    height: 4px;
    left: 20px;
    background: var(--matchAddDayBG);
    bottom: 12px;
    z-index: -1;
}

.itineraryDescriptionLink {
    color: var(--blue) !important;
}
/* .daysSelection > div::before{
   content: "";
   width: 100%;
   height: 4px;
   background-color: var(--lightGrey);
   position: absolute;
   bottom: 8px;
   z-index: -1;
   left: 0;
  }
  
  .daysSelection .currentDay::before,.daysSelection .currentDay::after{
    content: "";
    position: absolute;
    bottom: 8px;
    right: 0;
    width: 50%;
    height: 4px;
    z-index: -1;
    background-color: var(--blue);
  }
  .daysSelection .currentDay::after:not(:first-child){
    left: -50%;
  }
  .daysSelection > .currentDay:first-child::before{
    left: 50%;
  }
  .daysSelection > .currentDay:last-child::after{
    content: none;
  } */
.flexCenter {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
}
.flexWrap {
    flex-wrap: wrap;
}
.addPlaceBtn,
.addDayBtn {
    border: 1px solid var(--purple);
    color: var(--purple);
    font-size: 16px;
    padding: 0.5rem 2.5rem;
    background-color: transparent;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.4s;
    white-space: nowrap;
}
.addPlaceBtn:hover,
.addPlaceBtn:active,
.addPlaceBtn:focus,
.addDayBtn:hover,
.addDayBtn:focus,
.addDayBtn:active {
    background-color: var(--purple);
    color: white;
}
.daysBullet {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 10rem;
    display: inline-flex;
    background-color: var(--lightGrey) !important;
}
.currentDay .daysBullet{
    background-color: var(--purple) !important;
}
.daysWrapper {
    background: var(--lightGreenBG) !important;
    margin: 25px 0 !important;
    padding: 20px 30px !important;
    border-radius: 6px;
}
.cardInformation {
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: start;
}
.daysCounterWrap {
    display: flex;
    flex-direction: column;
}
.daysCount {
    width: 60px;
    height: 60px;
    background: var(--purple);
    position: relative;
    border-radius: 50px;
    color: white;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 0 auto;
}
.daysCount:before {
    content: "";
    position: absolute;
    right: 50%;
    top: 58px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid var(--purple);
    transform: translateX(50%);
}
.dates {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 25px;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}
.cardTimeline {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
.cardTimelineItem {
    display: flex;
    gap: 15px;
    align-items: start;
    position: relative;
    max-width: 49%;
}
.cardTimelineItem::before {
    content: "";
    position: absolute;
    left: 8px;
    bottom: 0px;
    height: calc(100% - 36px);
    width: 4px;
    background-color: var(--blue);
}
.cardTimelineItem img {
    filter: invert(21%) sepia(78%) saturate(1575%) hue-rotate(272deg)
        brightness(96%) contrast(99%);
}
.cardTimelineItem h5 {
    margin-bottom: 10px;
}
.cardTimelineItem p {
    font-size: 14px;
}
.cardActions {
    display: flex;
    gap: 15px;
    align-items: center;
}
.actionIcons {
    display: flex;
    align-items: center;
    gap: 8px;
}
.actionIcons .action-button-size {
    margin-left: 0;
}
.actionIcons .action-button-size img {
    height: 24px;
}
.places {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}
.placeAvatar {
    width: 130px;
    height: 85px;
    object-fit: cover !important;
    object-position: center;
}
.formWrapper {
    padding: 1.5rem !important;
    background-color: var(--lightBG) !important;
    border-radius: 6px !important;
}
.formWrapper label {
    font-size: 16px;
    font-weight: 600 !important;
}
.formWrapper textarea {
    font-size: 16px;
}
.addPlaceGoogle {
    padding-left: 2.5rem !important;
    background: url(../../../assets/media/map-pin.svg) no-repeat 12px 8px / 16px;
    min-height: 42px !important;
}
.placeOverlayWrap {
    position: relative; transition: all 400ms;
    
}
.filter-white{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(400%) contrast(100%);
}
.itin_edit_icon {
    background-color: #002d5e !important;
    border-radius: 50px;
    margin-left: 10px;
    margin-top: 5px;
}
.placeOverlay {
    background-color: rgba(0, 0,0,0.8);
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 6; */
    width: 100%;
    height: 100%;
    transform: scale(0);
    display: flex; align-items: flex-start; gap: 4px; justify-content: space-between;
}
.placeOverlay span img{
    display: inline-flex; 
    padding: 4px; 
    width: 25px
}

.placeOverlayWrap .gray_gradient {
    background: #666; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #666 0%, #999 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #666 0%,#999 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #666 0%,#999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#999999',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  
  .placeOverlayWrap .purple_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9a75e6+0,d858bb+100 */
    background: #9a75e6; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #9a75e6 0%, #d858bb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #9a75e6 0%,#d858bb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #9a75e6 0%,#d858bb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a75e6', endColorstr='#d858bb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  .placeOverlayWrap .approvalChk {
    /* padding: 5px 10px; */
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
  } 
  .placeOverlayWrap .approvalChk button.checkBtn {
    padding: 5px 10px;
    background: inherit;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
  }
 .placeOverlayWrap .approvalChk button.checkBtn:before {
    content: "";
    display: inline-flex;
    height: 16px;
    width: 16px;
    background: transparent;
    box-shadow: 0 0 0 2px #fff;
    margin-right: 9px;
  }
  .placeOverlayWrap .approvalChk button.checkBtn.checkedBtn:before {
    background:url('https://gtvoriginalimages.s3.amazonaws.com/static/img/select_gallery.png') no-repeat center, #b9d54a; /* Old browsers */
    background:url('https://gtvoriginalimages.s3.amazonaws.com/static/img/select_gallery.png') no-repeat center, -moz-linear-gradient(-45deg,  #b9d54a 0%, #c4d134 100%); /* FF3.6-15 */
    background:url('https://gtvoriginalimages.s3.amazonaws.com/static/img/select_gallery.png') no-repeat center, -webkit-linear-gradient(-45deg,  #b9d54a 0%,#c4d134 100%); /* Chrome10-25,Safari5.1-6 */
    background:url('https://gtvoriginalimages.s3.amazonaws.com/static/img/select_gallery.png') no-repeat center, linear-gradient(135deg,  #b9d54a 0%,#c4d134 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9d54a', endColorstr='#c4d134',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    background-size: 92% !important;
  }
  
  .placeOverlayWrap .approvalChk .customChk input[type='checkbox']+label {
    color: #fff !important;
    vertical-align: sub;
  }
  
  .placeOverlayWrap .approvalChk .customChk input[type='checkbox']+label:before {
    border-color: #fff;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    margin-left: 10px;
  }
  
  .placeOverlayWrap .approvalChk .customChk input[type='checkbox']:checked+label:before {
    background: #b9d54a; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #b9d54a 0%, #c4d134 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #b9d54a 0%,#c4d134 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #b9d54a 0%,#c4d134 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9d54a', endColorstr='#c4d134',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border-radius: 0;
  }

.placeOverlayWrap:hover .placeOverlay{
    transform: scale(1);
}

.placeOverlayWrap.imgblur> img{
    filter: blur(1.2px);
}

.input {
    background-color: white;
    min-height: 36px;
    padding: 6px;
    border: 1px solid var(--lightGrey) !important;
    border-radius: 4px;
}
.input .rc-time-picker-input {
    font-size: 16px;
    height: 36px;
    border: none;
    background: white url(../../../assets/media/clock.svg) no-repeat center
        right 8px / 18px;
    padding-right: 32px;
}
.input[disabled],
.input .rc-time-picker-input[disabled],
.form-control[disabled] {
    background-color: #f7f7f7 !important;
    color: #ccc !important;
    cursor: not-allowed;
}
button[disabled],
button[disabled]:hover {
    cursor: not-allowed;
    background-color: var(--lightGrey);
    color: #848383;
}
.input .rc-time-picker-clear {
    width: auto;
    height: auto;
    right: 40px;
}
.input .rc-time-picker-clear:focus {
    outline: none;
}
.input .rc-time-picker-clear-icon:after {
    font-size: 20px;
    height: auto;
    color: var(--danger);
    line-height: 1.5;
}
.plavesWalls img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    object-position: center;
}
@media all and (min-width: 1100px) and (max-width: 1280px) {
    .cardTimelineItem {
        width: 45%;
    }
}
@media all and (max-width: 1099.9px) {
    .cardActions {
        gap: 8px;
    }
    .cardTimeline .flexCenter.flexWrap {
        gap: 10px;
    }
    .cardTimeline .flexCenter.flexWrap .cardTimelineItem {
    }
    .actionIcons .action-button-size img {
        height: 18px;
    }
    .placeAvatar {
        width: 60px;
        height: 40px;
    }
}
@media all and (max-width: 768px) {
    .cardActions {
        gap: 5px;
    }
    .cardActions.msAuto {
        margin-left: auto;
        margin-right: auto;
    }

    .placeAvatar {
        width: 48px;
        height: 36px;
    }
    .addPlaceGoogle {
        background-size: 14px;
        background-position: 8px 9px;
        height: 42px;
    }
    .flexCenter {
        flex-direction: column;
        align-items: baseline;
    }
    .daysCount {
        width: 40px;
        min-width: 40px;
        height: 40px;
        font-size: 28px;
    }
    .daysCount:before {
        top: 32px;
    }
    .plavesWalls img {
        width: 120px;
        height: 80px;
    }
}
